import React from 'react';

const privacyPolicyEnglish = () => (
  <div className="b-privacy-policy__content grid-xl">
    <h1 className="b-privacy-policy__title">PROCESSING OF PERSONAL DATA FOR GOLFMORE APS (updated February 2025)</h1>
    <p className="b-privacy-policy__paragraph">
      Below, you can read how GolfMore ApS (“we”, “us”, “our”) processes your personal data when you use our app
      ("GolfMore App") or visit our website{' '}
      <a
        className="b-privacy-policy__link"
        href="https://www.golfmore.eu/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        www.golfmore.eu
      </a>
      .
    </p>

    <h2 className="b-privacy-policy__sub-title">1. DATA CONTROLLER</h2>
    <p className="b-privacy-policy__paragraph">The legal entity responsible for processing your personal data is:</p>
    <p className="b-privacy-policy__paragraph">
      GolfMore ApS
      <br />
      CVR no. 33593082
      <br />
      Rugaardsvej 5<br />
      8680 Ry
      <br />
      Denmark
    </p>
    <p className="b-privacy-policy__paragraph">
      If you have questions about our processing of your personal data, you can contact Peter Selmer at phone +45 7070
      7999 or email{' '}
      <a
        className="b-privacy-policy__link"
        href="mailto:info@golfmore.eu"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        info@golfmore.eu
      </a>
      .
    </p>

    <h2 className="b-privacy-policy__sub-title">
      2. CATEGORIES OF INFORMATION, PURPOSE OF PROCESSING, AND RETENTION PERIOD
    </h2>
    <p className="b-privacy-policy__paragraph">
      We may process your personal data in various situations. Below, you can read about when we process your personal
      data, for what purposes, and how long we retain it. Note that not all purposes, categories of data, recipients, or
      types of processing apply to you in all cases.
    </p>
    <p className="b-privacy-policy__paragraph">
      We aim to ensure that all personal data we process is accurate and up-to-date. Therefore, we ask you to always
      notify us of any changes to the information you have provided. This way, we can ensure your personal data is
      correct and updated.
    </p>

    <h3 className="b-privacy-policy__sub-inner-title">2.1 GolfMore App</h3>
    <p className="b-privacy-policy__paragraph">
      When you create a user profile in the GolfMore App, we process personal data about you, including your name (first
      and last name), contact information (email address and mobile number), date of birth, country of residence,
      gender, golf union membership number, home club, membership type, as well as your username and password for the
      GolfMore App.
    </p>
    <p className="b-privacy-policy__paragraph">
      If you choose to use your username and password from other services (e.g., GolfBox, Mingolf, Apple, or Google) to
      create a user profile in the GolfMore App, we collect personal data about you from the respective service for
      profile creation and login. Additionally, we process information about the purchases and payments you make in the
      GolfMore App, including booking details (where, when, with whom, and at what price you play golf), transaction
      history (purchases and use of points, vouchers, and gift cards), transfer of vouchers to/from other GolfMore App
      users, uploaded vouchers, and generated PDFs of vouchers.
    </p>
    <p className="b-privacy-policy__paragraph">
      Processing of this personal data is based on Article 6(1)(b) of the GDPR, as the processing is necessary for the
      creation and administration of your user account in the GolfMore App, including handling customer service and
      support requests from you. The data may also be used for preparing statistics and analyzing consumption patterns
      for business development purposes. Processing for these purposes is based on Article 6(1)(f) of the GDPR and our
      legitimate interest in further developing our business based on our experiences.
    </p>
    <p className="b-privacy-policy__paragraph">
      When you use the GolfMore App, we collect location data about you. We need to know your geographic location to
      activate your digital products. Therefore, it is only possible to use the GolfMore App when location services on
      your mobile device are enabled. Processing of location data is based on Article 6(1)(b) of the GDPR, as the
      processing is necessary for you to use your digital products in the GolfMore App.
    </p>
    <p className="b-privacy-policy__paragraph">
      The data is retained and processed as long as your user profile on the GolfMore App is active. If you have not
      logged into your user profile in the GolfMore App for 24 months and there are no active points, vouchers, etc., on
      the account, your user profile will automatically be deleted.
    </p>
    <p className="b-privacy-policy__paragraph">
      You can delete your user profile at any time, either by using the delete function in the GolfMore App or by
      sending a deletion request to us at{' '}
      <a
        className="b-privacy-policy__link"
        href="mailto:info@golfmore.eu"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        info@golfmore.eu
      </a>
      . Note that you will lose all unused points, vouchers, etc., when your profile is deleted.
    </p>
    <h3 className="b-privacy-policy__sub-inner-title">2.2 Website</h3>
    <p className="b-privacy-policy__paragraph">
      When you visit our website,{' '}
      <a
        className="b-privacy-policy__link"
        href="https://www.golfmore.eu/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        www.golfmore.eu
      </a>
      , we collect information about your visit through cookies and similar technologies. Cookies are small text files
      stored on your phone, tablet, computer, etc., which allow us to gather information about the websites you visit
      and the features you use on our website. This information helps us continuously optimize and tailor the website to
      your needs and interests. You can find information about the cookies we use, their purposes, how long they remain
      active, and who we share the information with in the cookie declaration on our website.
    </p>
    <p className="b-privacy-policy__paragraph">
      The information we collect through cookies and similar technologies can, in some cases, be personal data.
      Processing of personally identifiable information via cookies and similar technologies is based on Article 6(1)(a)
      of the GDPR (consent).
    </p>
    <p className="b-privacy-policy__paragraph">
      You have the right to withdraw your consent at any time. You can withdraw your consent by clicking on the small
      cookie icon displayed in the bottom left corner of the website and then clicking the “necessary only” button in
      the pop-up information box. You can also refuse cookies by changing your browser settings. The location of these
      settings depends on the browser you use. Guidance on how to delete cookies in your browser can be found on the
      following links. If you use multiple browsers, remember to delete cookies in all of them.
    </p>
    <p className="b-privacy-policy__paragraph">
      <a
        className="b-privacy-policy__link"
        href="https://support.google.com/accounts/answer/32050"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Google Chrome
      </a>
      <br />
      <a
        className="b-privacy-policy__link"
        href="https://support.microsoft.com/da-dk/windows/administrer-cookies-i-microsoft-edge-f%C3%A5-vist-tillad-bloker-slet-og-brug-168dab11-0753-043d-7c16-ede5947fc64d"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Microsoft Edge
      </a>
      <br />
      <a
        className="b-privacy-policy__link"
        href="https://support.microsoft.com/da-dk/windows/slet-og-administrer-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Internet Explorer
      </a>

      <br />
      <a
        className="b-privacy-policy__link"
        href="https://support.apple.com/da-dk/guide/safari/sfri11471/mac"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Safari
      </a>

      <br />
      <a
        className="b-privacy-policy__link"
        href="https://support.mozilla.org/da/kb/slet-cookies-fjerne-oplysninger-fra-websteder"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Mozilla Firefox
      </a>

      <br />
      <a
        className="b-privacy-policy__link"
        href="https://help.opera.com/en/latest/security-and-privacy/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Opera
      </a>

      <br />
      <a
        className="b-privacy-policy__link"
        href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Flash
      </a>
    </p>

    <p className="b-privacy-policy__paragraph">
      Our website may contain links to other websites. We are not responsible for the content of other websites or their
      potential collection and processing of your personal data when you visit them. Therefore, we recommend that you
      always read the privacy and cookie policies of the respective websites.
    </p>

    <h3 className="b-privacy-policy__sub-inner-title">2.3 Newsletters</h3>
    <p className="b-privacy-policy__paragraph">
      If you have subscribed to our newsletter, we process information about your name and email address, as well as any
      information you provide in this context. The purpose of processing personal data is to send you our newsletter and
      market our products and company to you. We also process information about your use of the newsletters we send you,
      including whether you have opened a newsletter, whether it has been read, and which links you clicked. We use this
      information to organize and optimize future newsletters.
    </p>
    <p className="b-privacy-policy__paragraph">
      We use Article 6(1)(a) of the GDPR (consent) as the legal basis for processing. You have the right to withdraw
      your consent at any time. You can withdraw your consent by clicking the unsubscribe link at the bottom of your
      most recent newsletter. Withdrawing your consent does not affect the processing of personal data that has already
      taken place prior to the withdrawal.
    </p>
    <p className="b-privacy-policy__paragraph">
      When you receive newsletters from us, we retain your information until the time you unsubscribe from our
      newsletter.
    </p>

    <h2 className="b-privacy-policy__sub-title">3. VOLUNTARINESS</h2>
    <p className="b-privacy-policy__paragraph">
      We collect personal data directly from you, and you provide the information voluntarily. Depending on the specific
      circumstances, the consequence of not providing personal data may be that we cannot fulfill the purposes described
      above, including that you cannot use the GolfMore App.
    </p>

    <h2 className="b-privacy-policy__sub-title">4. TRANSFER TO COUNTRIES OUTSIDE THE EU/EEA</h2>
    <p className="b-privacy-policy__paragraph">
      Data protection laws in countries outside the EU/EEA may be less strict than those in Denmark and the rest of the
      EU/EEA. However, in certain countries, the EU Commission has determined that the data protection level is
      equivalent to the level of protection in the EU/EEA. If we transfer your personal data to countries where this is
      not the case, the transfer will be made in accordance with the rules in Chapter 5 of the GDPR. If you have
      questions about our transfer of data to third countries, you can contact us at{' '}
      <a
        className="b-privacy-policy__link"
        href="mailto:info@golfmore.eu"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        info@golfmore.eu
      </a>
      .
    </p>

    <h2 className="b-privacy-policy__sub-title">5. RECIPIENTS</h2>
    <p className="b-privacy-policy__paragraph">
      Your personal data may be processed by others besides us. For example, it could be by our partners (golf clubs,
      golf courses, driving ranges, etc.) with whom you purchase digital products through the GolfMore App, or by our IT
      service providers in connection with the ongoing operation and support of our IT systems, including sending
      newsletters, analysis tasks, payment solutions, etc.
    </p>
    <p className="b-privacy-policy__paragraph">
      We disclose information about you when it is necessary for the operation of our business. When you purchase or use
      the digital products you have purchased from our partners in the GolfMore App, we disclose information about your
      name, email address, membership number, and home club, as well as information about your use of points, vouchers,
      or gift cards, to the respective partner delivering the services to you. Disclosure is based on Article 6(1)(b) of
      the GDPR, as it is necessary for you to enter into an agreement with the respective partner about the purchase or
      use of digital products from them. For business development purposes, we also disclose to the respective partner
      statistics and analyses of consumption patterns regarding their customers' purchases and use of digital products
      in the GolfMore App. Processing for these purposes is based on Article 6(1)(f) of the GDPR and our partners'
      legitimate interest in further developing their business based on their sales of digital products through the
      GolfMore App. For more information about their processing of your personal data, we refer to their privacy
      policies and terms.
    </p>

    <h2 className="b-privacy-policy__sub-title">6. YOUR RIGHTS</h2>
    <h3 className="b-privacy-policy__sub-inner-title">Right of access</h3>
    <p className="b-privacy-policy__paragraph">
      You have the right to access the information we process about you, as well as receive additional information about
      our processing of your personal data.
    </p>
    <h3 className="b-privacy-policy__sub-inner-title">Right to rectification</h3>
    <p className="b-privacy-policy__paragraph">
      You have the right to have incorrect information about yourself corrected and to have incomplete information about
      yourself supplemented. This can, among other things, happen by providing us with additional information.
    </p>
    <h3 className="b-privacy-policy__sub-inner-title">Right to erasure</h3>
    <p className="b-privacy-policy__paragraph">
      You can request to have your personal data deleted under certain circumstances. In some cases, you have the right
      to have information about yourself deleted. This is the case, for example, when the information is no longer
      necessary to fulfill the purposes for which it was collected or processed, or if the processing of the information
      is unlawful.
    </p>
    <h3 className="b-privacy-policy__sub-inner-title">Right to restriction of processing</h3>
    <p className="b-privacy-policy__paragraph">
      In certain cases, you have the right to restrict the processing of your personal data. If you have the right to
      restrict processing, it means that we may only process the data – apart from storage – with your consent, or for
      the establishment, exercise, or defense of legal claims, or to protect a person or important public interests.
    </p>
    <h3 className="b-privacy-policy__sub-inner-title">Right to data portability</h3>
    <p className="b-privacy-policy__paragraph">
      In certain cases, you have the right to receive the personal data you have provided to us in a structured,
      commonly used, and machine-readable format, and to have this personal data transferred from us to another data
      controller without hindrance.
    </p>
    <h3 className="b-privacy-policy__sub-inner-title">Right to object</h3>
    <p className="b-privacy-policy__paragraph">
      You have the right at any time – for reasons related to your particular situation – to object to our otherwise
      lawful processing of your personal data.
    </p>
    <h3 className="b-privacy-policy__sub-inner-title">Right to withdraw consent</h3>
    <p className="b-privacy-policy__paragraph">
      You have the right at any time to withdraw your consent to the processing of your personal data. However, you
      should be aware that this only takes effect from the time of withdrawal. It therefore does not affect the legality
      of our processing of your personal data up until the time you withdraw your consent.
    </p>
    <p className="b-privacy-policy__paragraph">
      You can read more about your rights on the Danish Data Protection Agency's website.
    </p>
    <p className="b-privacy-policy__paragraph">
      If you wish to exercise one or more of your rights, you can contact Peter Selmer at phone +45 7070 7999 or email{' '}
      <a
        className="b-privacy-policy__link"
        href="mailto:info@golfmore.eu"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        info@golfmore.eu
      </a>
      .
    </p>

    <h2 className="b-privacy-policy__sub-title">7. COMPLAINT</h2>
    <p className="b-privacy-policy__paragraph">
      Complaints about our processing of your personal data can be directed to:
    </p>
    <p className="b-privacy-policy__paragraph">
      Danish Data Protection Agency
      <br />
      Carl Jacobsens Vej 35
      <br />
      2500 Valby
      <br />
      Email:{' '}
      <a
        className="b-privacy-policy__link"
        href="mailto:dt@datatilsynet.dk"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        dt@datatilsynet.dk
      </a>
    </p>

    <h2 className="b-privacy-policy__sub-title">8. UPDATING THE PRIVACY POLICY</h2>
    <p className="b-privacy-policy__paragraph">
      From time to time, it will be necessary for us to update this privacy policy. Therefore, it is a good idea to keep
      yourself regularly updated. For significant changes, we will inform you via the GolfMore App or email. We publish
      new versions on the GolfMore App and on our website{' '}
      <a
        className="b-privacy-policy__link"
        href="https://www.golfmore.eu/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        www.golfmore.eu
      </a>
      .
    </p>
  </div>
);

export default privacyPolicyEnglish;
