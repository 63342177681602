import React from 'react';
import urls from '../../../../constants/config/urls';

const termsDanish = () => (
  <div className="b-terms__content container grid-xl">
    <div className="columns">
      <div className="column">
        <h1 className="b-terms__title">Generelle betingelser for GolfMore App (Opdateret Februar 2025)</h1>

        <h3 className="b-terms__sub-title">1. Om GolfMore App</h3>
        <p className="b-terms__paragraph">
          GolfMore App (”Appen”) er en e-handelsplatform, der drives af GolfMore ApS (”Golfmore” ””vi”, ”os”, ”vores”),
          som retter sig mod golfspillere (’’brugeren”, ”du”, ”dig” ”dit”) som købere, samt golfklubber, golfbaner og
          driving ranges (”GolfMore-partnere”) som sælgere af digitale golfprodukter. Du kan, ved at oprette en
          brugerprofil, bruge Appen til at købe og bruge digitale produkter såsom point, værdikuponer og gavekort
          direkte hos GolfMore-partnere.
        </p>
        <p className="b-terms__paragraph">
          Når du opretter en brugerprofil, indgår du en aftale med GolfMore ApS, CVR-nr. 33593082, Rugaardsvej 5, 8680
          Ry, Danmark, om brug af Appen på de nedenstående vilkår og betingelser.
        </p>
        <p className="b-terms__paragraph">
          Hvis du har spørgsmål vedrørende disse betingelser eller Appen, er du meget velkommen til at kontakte os på
          e-mail:{' '}
          <a
            className="b-privacy-policy__link"
            href="mailto:info@golfmore.eu"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            info@golfmore.eu
          </a>{' '}
          eller på telefon +45 7070 7999.
        </p>

        <h3 className="b-terms__sub-title">2. Brugerprofiler</h3>
        <h3 className="b-terms__sub-title">2.1 Oprettelse af brugerprofiler</h3>
        <p className="b-terms__paragraph">
          For at kunne bruge Appen skal du oprette en brugerprofil. Profilen er personlig og du må kun oprette én
          profil. Du må ikke opgive en anden persons e-mail eller udgive dig for at være en anden person. Når du
          opretter en brugerprofil, skal du angive en personlig adgangskode. Koden kan efterfølgende ændres i Appen
          eller ved at kontakte os på{' '}
          <a
            className="b-privacy-policy__link"
            href="mailto:info@golfmore.eu"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            info@golfmore.eu
          </a>
          . Det er dit ansvar at adgangskoden holdes personlig og hemmelig. Koden må ikke deles med andre. Du kan også
          vælge at bruge dit brugernavn og password fra andre tjenester (f.eks. GolfBox, Mingolf, Apple eller Google)
          til profiloprettelse og login i GolfMore Appen. Hvis du gør det, indhenter vi personoplysninger om dig fra den
          pågældende tjeneste til brug for profiloprettelse og login. Ved misbrug af din brugerprofil eller ved mistanke
          herom skal GolfMore straks informeres.
        </p>

        <h3 className="b-terms__sub-title">2.2 Sletning af brugerprofiler</h3>
        <p className="b-terms__paragraph">
          Du kan til enhver tid fortryde oprettelsen af din brugerprofil ved at bruge slettefunktionen i Appen eller ved
          at sende en sletteanmodning til os på{' '}
          <a
            className="b-privacy-policy__link"
            href="mailto:info@golfmore.eu"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            info@golfmore.eu
          </a>
          . Du skal være opmærksom på, at du mister alle dine ubenyttede point, værdikuponer mv., når din profil
          slettes.
        </p>

        <h3 className="b-terms__sub-title">3. Aftaleindgåelse, fakturering og betaling</h3>
        <h3 className="b-terms__sub-title">3.1 Købsaftalens parter</h3>
        <p className="b-terms__paragraph">
          Appen giver dig mulighed for at købe digitale golfprodukter direkte hos GolfMore-partnere. Den købsaftale, der
          indgås, når du foretager et køb i Appen, indgår du direkte med den GolfMore-partner, der i Appen står opført
          som sælger af produktet. Den købsaftale, der indgås ved afslutningen af et køb i Appen, er derfor udelukkende
          mellem dig og den pågældende GolfMore-partner. GolfMore er ikke part i denne købsaftale og vi påtager os
          derfor heller ikke noget ansvar i forbindelse med den indgåede købsaftale, ligesom vi ikke agerer som
          GolfMore-partnerens agent. Det er derfor alene GolfMore-partneren, der er ansvarlig for salget af produkterne
          og for at håndtere eventuelle krav fra dig, samt for at besvare andre spørgsmål der måtte opstå i forbindelse
          med købsaftalen mellem jer.
        </p>

        <h3 className="b-terms__sub-title">3.2 Fakturering og betaling</h3>
        <p className="b-terms__paragraph">
          Når du foretager et køb i Appen, modtager du en faktura fra den GolfMore-partner, der er part i købsaftalen.
          Fakturaer sendes på e-mail. Betalingen for de købte produkter sker direkte til den GolfMore-partner, der er
          part i købsaftalen. Kortbetalinger i Appen sker gennem en godkendt betalingsserver, som krypterer alle
          betalingskortoplysninger med SSL-protokol. GolfMore kan derfor ikke læse dine betalingskortoplysninger.
        </p>

        <h3 className="b-terms__sub-title">4. Points</h3>
        <p className="b-terms__paragraph">
          I Appen kan du købe digitale points hos GolfMore-partnere. Når dit pointkøb er gennemført, bliver dine points
          registreret på din pointsaldo på din brugerprofil. Gyldighedsperioden for dine points fremgår i Appen. Points
          kan bruges til at købe udvalgte ydelser hos den GolfMore-partner, som du har købt dine points hos. Points kan
          ikke konverteres til kontanter. For at bruge dine points skal du logge ind på din brugerprofil. Her kan du se
          GolfMore-partnerens aktuelle udbud af ydelser, som du kan bruge dine points på. Ydelser købt for points kan
          ikke fortrydes og kan ikke ombyttes til andre ydelser eller til kontanter.
        </p>

        <h3 className="b-terms__sub-title">5. Værdikuponer og gavekort</h3>
        <p className="b-terms__paragraph">
          I Appen kan du købe værdikuponer og gavekort hos GolfMore-partnere. Når dit køb er gennemført, vil dine
          værdikuponer og/eller gavekort være tilgængelige på din brugerprofil. Gavekort og værdikuponer er underlagt de
          særlige vilkår, herunder vilkår for brug, gyldighedsperiode, refundering m.v., som i Appen er angivet for det
          pågældende gavekort eller værdikupon.
        </p>

        <h3 className="b-terms__sub-title">6. Fortrydelsesret</h3>
        <p className="b-terms__paragraph">
          Forbrugere, som defineret i den til enhver tid gældende forbrugeraftalelov, har en fortrydelsesret på 14 dage.
          Det betyder, at du kan annullere en købsaftale, du har indgået i Appen, inden for 14 dage efter modtagelse af
          produktet. Det gør du ved at meddele den GolfMore-partner, du har indgået købsaftalen med, at du ikke ønsker
          at fastholde aftalen, f.eks. pr. e-mail eller ved bruge den standardfortrydelsesformular, som følger af
          forbrugeraftaleloven, og som du finder{' '}
          <a
            className="b-privacy-policy__link"
            href="https://www.retsinformation.dk/eli/lta/2013/1457#Bil3) "
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            her
          </a>
          .
        </p>
        <p className="b-terms__paragraph">
          Hvis fortrydelsesretten skal gøres gældende, skal dette ske senest ved udløbet af 14 dages fristen. Alle
          spørgsmål, der vedrører fortrydelsesret håndteres direkte mellem dig og den GolfMore-Partner, du har indgået
          købsaftalen med. Der gøres udtrykkeligt opmærksomt på, at fortrydelsesretten bortfalder så snart du tager det
          digitale produkt i brug, også selvom dette sker inden for 14 dage fra din modtagelse af produktet, når
          leveringen er påbegyndt med dit forudgående udtrykkelige samtykke og anerkendelse af dermed at miste din
          fortrydelsesret.
        </p>

        <h3 className="b-terms__sub-title">7. Kundeserviceforespørgsler og klageadgang</h3>
        <h3 className="b-terms__sub-title">7.1 Kundeserviceforespørgsler</h3>
        <p className="b-terms__paragraph">
          GolfMore er ikke part i de købsaftaler, der indgås ved brug af Appen. Alle henvendelser med relation til disse
          købsaftaler, herunder henvendelser om support, reklamation samt alle øvrige kundeserviceforespørgsler skal
          derfor rettes direkte til den GolfMore-Partner, købsaftalen er indgået med.
        </p>

        <h3 className="b-terms__sub-title">7.2 Klageadgang</h3>
        <p className="b-terms__paragraph">
          Hvis du vil klage over et køb i Appen, skal du kontakte den GolfMore-partner, der er part i den købsaftale, du
          ønsker at klage over. Du kan også indgive en klage til Nævnenes Hus, Toldboden 2, 8800 Viborg, via
          <a
            className="b-privacy-policy__link"
            href="https://naevneneshus.dk/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            www.naevneneshus.dk
          </a>
          . Har du bopæl i et andet EU-land end Danmark, kan du klage til EU-Kommissionens online klageportal via{' '}
          <a
            className="b-privacy-policy__link"
            href="https://ec.europa.eu/consumers/odr"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            www.ec.europa.eu/odr
          </a>
          .
        </p>

        <h3 className="b-terms__sub-title">8. Behandling af personoplysninger</h3>
        <p className="b-terms__paragraph">
          GolfMore er dataansvarlig for de personoplysninger, der behandles i Appen. Information om vores behandling af
          personoplysningerne findes i vores{' '}
          <a
            className="b-privacy-policy__link"
            href={urls.danishPrivacyPolicy}
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            privatlivspolitik
          </a>
          . Her er bl.a. information om formålet med og retsgrundlaget for behandlingen, dine rettigheder efter
          databeskyttelsesforordningen samt anden relevant information til dig om vores behandling af dine
          personoplysninger i forbindelse din brug af Appen.
        </p>

        <h3 className="b-terms__sub-title">9. Immaterielle rettigheder</h3>
        <p className="b-terms__paragraph">
          GolfMore og dets licensgivere ejer alle immaterielle rettigheder, herunder ophavsrettighederne, til Appen,
          uafhængig af form og format, og rettighederne til varemærker, kode, grafiske elementer m.v. på Appen. Brugen
          har brugsret til Appen på de vilkår, der fremgår af betingelserne. Brugeren erhverver herudover ingen
          rettigheder til Appen eller dele heraf, og brugeren må ikke på nogen måde kopiere nogen del af Appen eller
          benytte dette i videre omfang end udtrykkeligt tilladt i betingelserne.
        </p>

        <h3 className="b-terms__sub-title">10. Ansvar</h3>
        <p className="b-terms__paragraph">
          Vi gør vores bedste for at sikre, at Appen er tilgængelige hele tiden, og at transmissionerne fungerer
          fejlfrit. Dette kan dog ikke garanteres. Der kan således forekomme situationer, hvor Appen af forskellige
          årsager ikke er tilgængelig eller hvor din adgang til Appen er begrænset. Vi forsøger så vidt muligt at
          begrænse hyppigheden og varigheden af sådanne begrænsninger i Appens tilgængelighed, men vi kan ikke garantere
          Appens tilgængelighed eller at Appen er fejlfri eller uden forstyrrelser og driftsnedbrud. Appen tilbydes i
          den form, som den leveres, og i det omfang, som den er tilgængelig.
        </p>
        <p className="b-terms__paragraph">
          GolfMore er ikke part i de købsaftaler, der indgås ved brug af Appen og vi påtager os derfor heller ikke noget
          ansvar i forbindelse med de købsaftaler, der indgås ved brug af Appen. Det er alene GolfMore-partneren, der er
          ansvarlig for salget af produkterne og for at håndtere eventuelle krav fra dig i den forbindelse. På den
          baggrund fraskriver vi os ethvert ansvar over for dig i denne henseende.
        </p>

        <h3 className="b-terms__sub-title">11. Ændring af betingelser</h3>
        <p className="b-terms__paragraph">
          GolfMore forbeholder sig ret til på ethvert tidspunkt at ændre disse betingelser. Ved væsentlige ændringer
          informerer vi dig i Appen eller på e-mail. De til enhver tid gældende betingelser er tilgængelige i Appen og
          på vores hjemmeside{' '}
          <a
            className="b-privacy-policy__link"
            href="https://www.golfmore.eu/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            www.golfmore.eu
          </a>
          .
        </p>

        <h3 className="b-terms__sub-title">12. Lovvalg og værneting</h3>
        <p className="b-terms__paragraph">
          Disse betingelser og brugen af Appen er underlagt og skal fortolkes i overensstemmelse med dansk ret, med
          undtagelse af internationale privatretlige regler, medmindre andet følger af præceptiv lovgivning. Eventuelle
          tvister, der ikke kan løses i mindelighed, løses ved den kompetente domstol ved GolfMores hjemting.
        </p>
      </div>
    </div>
  </div>
);

export default termsDanish;
