import React from 'react';
import urls from '../../../../constants/config/urls';

const termsEnglish = () => (
  <div className="b-terms__content container grid-xl">
    <div className="columns">
      <div className="column">
        <h1 className="b-terms__title">General Terms and Conditions for the GolfMore App (updated February 2025)</h1>

        <h3 className="b-terms__sub-title">1. About the GolfMore App</h3>
        <p className="b-terms__paragraph">
          The GolfMore App (the “App”) is an e-commerce platform operated by GolfMore ApS (“GolfMore,” “we,” “us,”
          “our”), targeting golfers (the “user,” “you,” “your”) as buyers, as well as golf clubs, golf courses, and
          driving ranges (“GolfMore Partners”) as sellers of digital golf products. By creating a user profile, you can
          use the App to purchase and use digital products such as points, vouchers, and gift cards directly from
          GolfMore Partners.
        </p>
        <p className="b-terms__paragraph">
          By creating a user profile, you enter into an agreement with GolfMore ApS, CVR no. 33593082, Rugaardsvej 5,
          8680 Ry, Denmark, regarding the use of the App under the terms and conditions below.
        </p>
        <p className="b-terms__paragraph">
          If you have questions regarding these terms or the App, you are welcome to contact us via email at{' '}
          <a
            className="b-privacy-policy__link"
            href="mailto:info@golfmore.eu"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            info@golfmore.eu
          </a>{' '}
          or by phone at +45 7070 7999.
        </p>

        <h3 className="b-terms__sub-title">2. User Profiles</h3>
        <h3 className="b-terms__sub-title">2.1 Creation of User Profiles</h3>
        <p className="b-terms__paragraph">
          To use the App, you must create a user profile. The profile is personal, and you are only allowed to create
          one profile. You must not use another person’s email or impersonate anyone else. When creating a user profile,
          you must set a personal password. This password can be changed later in the App or by contacting us at{' '}
          <a
            className="b-privacy-policy__link"
            href="mailto:info@golfmore.eu"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            info@golfmore.eu
          </a>
          . It is your responsibility to keep the password personal and confidential. The password must not be shared
          with others. You may also choose to use your username and password from other services (e.g., GolfBox,
          MinGolf, Apple, or Google) for profile creation and login in the GolfMore App. If you do so, we will collect
          personal data from the relevant service to use for profile creation and login. If your user profile is misused
          or if there is a suspicion of misuse, you must inform GolfMore immediately.
        </p>

        <h3 className="b-terms__sub-title">2.2 Deletion of User Profiles</h3>
        <p className="b-terms__paragraph">
          You can cancel the creation of your user profile at any time by using the delete function in the App or by
          sending a deletion request to us at{' '}
          <a
            className="b-privacy-policy__link"
            href="mailto:info@golfmore.eu"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            info@golfmore.eu
          </a>
          . Please note that you will lose all unused points, vouchers, etc., when your profile is deleted.
        </p>

        <h3 className="b-terms__sub-title">3. Agreements, Billing, and Payment</h3>
        <h3 className="b-terms__sub-title">3.1 Purchase Agreements</h3>
        <p className="b-terms__paragraph">
          The App enables you to purchase digital golf products directly from GolfMore Partners. The purchase agreement
          you enter into when making a purchase in the App is directly with the GolfMore Partner listed in the App as
          the seller of the product. Therefore, the purchase agreement made at the conclusion of a purchase in the App
          is solely between you and the relevant GolfMore Partner. GolfMore is not a party to this purchase agreement
          and thus assumes no responsibility for it. Similarly, we do not act as an agent for the GolfMore Partner. It
          is solely the responsibility of the GolfMore Partner to handle the sale of products and address any claims
          from you, as well as answer other questions related to the purchase agreement between you.
        </p>

        <h3 className="b-terms__sub-title">3.2 Billing and Payment</h3>
        <p className="b-terms__paragraph">
          When you make a purchase in the App, you will receive an invoice from the GolfMore Partner who is a party to
          the purchase agreement. Invoices are sent via email. Payment for the purchased products is made directly to
          the GolfMore Partner involved in the purchase agreement. Card payments in the App are processed through an
          approved payment server that encrypts all card information using SSL protocol. Therefore, GolfMore cannot
          access your payment card information.
        </p>

        <h3 className="b-terms__sub-title">4. Points</h3>
        <p className="b-terms__paragraph">
          In the App, you can purchase digital points from GolfMore Partners. Once your point purchase is completed,
          your points will be registered in your points balance in your user profile. The validity period for your
          points is indicated in the App. Points can be used to purchase selected services from the GolfMore Partner
          from whom you purchased your points. Points cannot be converted into cash. To use your points, you must log in
          to your user profile. Here, you can view the current offerings from the GolfMore Partner where you can use
          your points. Services purchased with points cannot be canceled and cannot be exchanged for other services or
          cash.
        </p>

        <h3 className="b-terms__sub-title">5. Vouchers and Gift Cards</h3>
        <p className="b-terms__paragraph">
          In the App, you can purchase vouchers and gift cards from GolfMore Partners. Once your purchase is completed,
          your vouchers and/or gift cards will be available in your user profile. Gift cards and vouchers are subject to
          specific terms, including terms of use, validity periods, refunds, etc., as indicated in the App for the
          specific gift card or voucher.
        </p>

        <h3 className="b-terms__sub-title">6. Right of Withdrawal</h3>
        <p className="b-terms__paragraph">
          Consumers, as defined by the applicable consumer protection laws, have a 14-day right of withdrawal. This
          means you can cancel a purchase agreement entered into in the App within 14 days of receiving the product. To
          do so, you must notify the GolfMore Partner with whom you entered the purchase agreement, for example, by
          email or by using the standard withdrawal form provided under consumer protection laws, which can be found{' '}
          <a
            className="b-privacy-policy__link"
            href="https://www.retsinformation.dk/eli/lta/2013/1457#Bil3) "
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            here
          </a>
          . If you wish to exercise your right of withdrawal, you must do so no later than the expiration of the 14-day
          period. All questions regarding the right of withdrawal must be addressed directly with the GolfMore Partner
          with whom you entered the purchase agreement.
        </p>
        <p className="b-terms__paragraph">
          Please note that the right of withdrawal expires as soon as you start using the digital product, even if this
          happens within 14 days of receiving the product. This applies when delivery has commenced with your prior
          express consent and acknowledgment of the forfeiture of your right of withdrawal.
        </p>

        <h3 className="b-terms__sub-title">7. Customer Service Inquiries and Complaints</h3>
        <h3 className="b-terms__sub-title">7.1 Customer Service Inquiries</h3>
        <p className="b-terms__paragraph">
          GolfMore is not a party to the purchase agreements entered into via the App. Therefore, all inquiries related
          to these purchase agreements, including support, complaints, and all other customer service inquiries, must be
          directed to the GolfMore Partner with whom the purchase agreement was made.
        </p>

        <h3 className="b-terms__sub-title">7.2 Complaints</h3>
        <p className="b-terms__paragraph">
          If you wish to file a complaint regarding a purchase made in the App, you must contact the GolfMore Partner
          who is a party to the purchase agreement you wish to complain about. You may also file a complaint with the
          Center for Complaints Resolution, Toldboden 2, 8800 Viborg, via{' '}
          <a
            className="b-privacy-policy__link"
            href="https://naevneneshus.dk/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            www.naevneneshus.dk
          </a>
          . If you reside in another EU country besides Denmark, you can file a complaint through the EU Commission's
          online complaint portal at{' '}
          <a
            className="b-privacy-policy__link"
            href="https://ec.europa.eu/consumers/odr"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            www.ec.europa.eu/odr
          </a>{' '}
          .
        </p>

        <h3 className="b-terms__sub-title">8. Processing of Personal Data</h3>
        <p className="b-terms__paragraph">
          GolfMore is the data controller for the personal data processed in the App. Information about our processing
          of personal data can be found in our{' '}
          <a
            className="b-privacy-policy__link"
            href={urls.englishPrivacyPolicy}
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Privacy Policy
          </a>
          . This includes information about the purpose and legal basis for processing, your rights under the General
          Data Protection Regulation, and other relevant details about how we handle your personal data in connection
          with your use of the App.
        </p>

        <h3 className="b-terms__sub-title">9. Intellectual Property Rights</h3>
        <p className="b-terms__paragraph">
          GolfMore and its licensors own all intellectual property rights, including copyrights, for the App, regardless
          of form or format, as well as the rights to trademarks, code, graphic elements, etc., on the App. The user is
          granted a right to use the App under the terms outlined in these conditions. Beyond this, the user does not
          acquire any rights to the App or parts thereof, and the user may not copy any part of the App or use it beyond
          what is expressly permitted in these terms.
        </p>

        <h3 className="b-terms__sub-title">10. Liability</h3>
        <p className="b-terms__paragraph">
          We do our best to ensure that the App is available at all times and that transmissions function without
          errors. However, this cannot be guaranteed. There may be instances where the App is unavailable for various
          reasons or where your access to the App is restricted. We strive to minimize the frequency and duration of
          such restrictions, but we cannot guarantee the availability of the App or that it is error-free or without
          interruptions and downtime. The App is offered in its current form and as available.
        </p>
        <p className="b-terms__paragraph">
          GolfMore is not a party to the purchase agreements entered into via the App and therefore assumes no
          responsibility for these agreements. Responsibility for the sale of products and handling any claims rests
          solely with the GolfMore Partner involved. Accordingly, we disclaim all liability to you in this regard.
        </p>

        <h3 className="b-terms__sub-title">11. Changes to Terms</h3>
        <p className="b-terms__paragraph">
          GolfMore reserves the right to change these terms at any time. In the event of significant changes, we will
          inform you via the App or email. The current terms will always be available in the App and on our website at{' '}
          <a
            className="b-privacy-policy__link"
            href="https://www.golfmore.eu/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            www.golfmore.eu
          </a>
          .
        </p>

        <h3 className="b-terms__sub-title">12. Governing Law and Jurisdiction</h3>
        <p className="b-terms__paragraph">
          These terms and the use of the App are governed by and interpreted in accordance with Danish law, excluding
          international private law rules unless otherwise stipulated by mandatory legislation. Any disputes that cannot
          be resolved amicably will be settled by the competent courts in the jurisdiction of GolfMore's registered
          office.
        </p>
      </div>
    </div>
  </div>
);

export default termsEnglish;
