import React from 'react';

const privacyPolicyDanish = () => (
  <div className="b-privacy-policy__content grid-xl">
    <h1 className="b-privacy-policy__title">
      BEHANDLING AF PERSONOPLYSNINGER FOR GOLFMORE APS (Opdateret Februar 2025)
    </h1>
    <p className="b-privacy-policy__paragraph">
      Nedenfor kan du læse, hvordan GolfMore ApS (“vi”, “os”, “våres”) behandler dine personoplysninger, når du bruger
      vores app (”GolfMore Appen”) eller besøger vores hjemmeside{' '}
      <a
        className="b-privacy-policy__link"
        href="https://www.golfmore.eu/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        www.golfmore.eu
      </a>
      .
    </p>

    <h2 className="b-privacy-policy__sub-title">1. DATAANSVAR</h2>
    <p className="b-privacy-policy__paragraph">
      Den juridiske enhed, der er dataansvarlig for behandlingen af dine personoplysninger er:
    </p>
    <p className="b-privacy-policy__paragraph">
      GolfMore ApS
      <br />
      CVR-nr. 33593082
      <br />
      Rugaardsvej 5<br />
      8680 Ry
      <br />
      Danmark
    </p>
    <p className="b-privacy-policy__paragraph">
      Hvis du har spørgsmål til vores behandling af dine personoplysninger, kan du kontakte Peter Selmer på telefon +45
      7070 7999 eller e-mail{' '}
      <a
        className="b-privacy-policy__link"
        href="mailto:info@golfmore.eu"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        info@golfmore.eu
      </a>
      .
    </p>

    <h2 className="b-privacy-policy__sub-title">
      2. KATEGORIER AF OPLYSNINGER, FORMÅLET MED BEHANDLINGEN OG OPBEVARINGSPERIODE
    </h2>
    <p className="b-privacy-policy__paragraph">
      Vi kan behandle personoplysninger om dig i en række forskellige situationer. Nedenfor kan du bl.a. læse om,
      hvornår vi behandler dine personoplysninger, til hvilke formål vi behandler dem, og hvor længe vi opbevarer dem.
      Bemærk, at det ikke er samtlige formål, kategorier af oplysninger, modtagere af oplysninger eller typer af
      behandling der gælder for dig i alle tilfælde.
    </p>
    <p className="b-privacy-policy__paragraph">
      Vi vil gerne sikre at alle personoplysninger, vi behandler, er korrekte og opdaterede. Vi beder dig derfor om
      altid at give os besked om eventuelle ændringer til de oplysninger, du har givet os. På den måde kan vi sikre, at
      dine personoplysninger er korrekte og ajourførte.
    </p>

    <h3 className="b-privacy-policy__sub-inner-title">2.1 GolfMore App</h3>
    <p className="b-privacy-policy__paragraph">
      Når du opretter en brugerprofil i GolfMore Appen behandler vi en række personoplysninger om dig, herunder dit navn
      (fornavn og efternavn), dine kontaktinformationer (e-mailadresse og mobilnummer), fødselsdato, bopælsland, køn,
      golfunion medlemsnummer, hjemmeklub, medlemstype samt dit brugernavn og din adgangskode til GolfMore Appen.
    </p>
    <p className="b-privacy-policy__paragraph">
      Hvis du vælger at bruge dit brugernavn og password fra andre tjenester (f.eks. GolfBox, Mingolf, Apple eller
      Google) til at oprette en brugerprofil i GolfMore Appen, indhenter vi personoplysninger om dig fra den pågældende
      tjeneste til brug for profiloprettelse og login. Derudover behandler vi oplysninger om de køb og betalinger, som
      du foretager i GolfMore Appen, herunder bookingoplysninger (hvor, hvornår, med hvem og til hvilken pris du spiller
      golf), transaktionshistorik (køb og brug af point, værdikuponer og gavekort), overførsel af værdikuponer til/fra
      andre brugere af GolfMore Appen, uploadede værdikuponer og genererede PDF’er af værdikuponer.
    </p>
    <p className="b-privacy-policy__paragraph">
      Behandling af disse personoplysninger sker på grundlag af databeskyttelsesforordningens artikel 6, stk. 1, litra
      b, da behandlingen af oplysningerne er nødvendig for oprettelsen og administrationen af din brugerkonto til
      GolfMore Appen, herunder til brug for håndtering af kundeservice- og supportforespørgsler fra dig. Oplysningerne
      kan desuden bruges til udarbejdelse af statistik og analyse af forbrugsmønstre til brug for forretningsudvikling.
      Behandling til disse formål sker på baggrund af databeskyttelsesforordningens artikel 6, stk. 1, litra f, og vores
      legitime interesse i at kunne videreudvikle vores forretning på baggrund af vores erfaringer.
    </p>

    <p className="b-privacy-policy__paragraph">
      Når du bruger GolfMore Appen, indsamler vi lokationsdata om dig. Vi har behov for at kende din geografiske
      lokation for at kunne aktivere dine digitale produkter. Det er derfor kun muligt at benytte GolfMore Appen, når
      lokalitetstjenester på din mobile enhed er slået til. Behandling af lokationsdata sker på baggrund af
      databeskyttelsesforordningens artikel 6, stk. 1, litra b, da behandlingen af oplysningerne er nødvendig for at du
      kan gøre brug af dine digitale produkter i GolfMore Appen.
    </p>

    <p className="b-privacy-policy__paragraph">
      Oplysningerne opbevares og behandles så længe din brugerprofil på GolfMore Appen er aktiv. Har du ikke har været
      logget ind på din brugerprofil i GolfMore Appen i en periode på 24 måneder, og er der ikke er aktive point,
      værdikuponer mv. på kontoen, vil din brugerprofil automatisk blive slettet.
    </p>

    <p className="b-privacy-policy__paragraph">
      Du kan til enhver tid slette din brugerprofil, enten ved at bruge slettefunktionen i GolfMore Appen, eller ved at
      sende en sletteanmodning til os på{' '}
      <a
        className="b-privacy-policy__link"
        href="mailto:info@golfmore.eu"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        info@golfmore.eu
      </a>
      . Du skal være opmærksom på, at du mister de alle dine ubenyttede point, værdikuponer mv. når din profil slettes.
    </p>

    <h3 className="b-privacy-policy__sub-inner-title">2.2 Hjemmeside</h3>
    <p className="b-privacy-policy__paragraph">
      Når du besøger vores hjemmeside,{' '}
      <a
        className="b-privacy-policy__link"
        href="https://www.golfmore.eu/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        www.golfmore.eu
      </a>
      , indsamler vi via cookies og lignende teknologier oplysninger om dit besøg. Cookies er små tekstfiler, der lagres
      på din telefon, tablet, computer mv., som blandt andet gør det muligt for os at indsamle information om hvilke
      hjemmesider du besøger, og hvilke af funktionerne på vores hjemmeside du benytter. Den information bruger vi til
      løbende at optimere og målrette hjemmesiden til dine behov og interesser. Oplysninger om hvilke cookies vi bruger,
      formålet med dem, hvor længe de er aktive, og hvem vi deler informationen med, finder du i cookiedeklarationen på
      vores hjemmeside.
    </p>
    <p className="b-privacy-policy__paragraph">
      De oplysninger, vi indsamler via cookies og lignende teknologier, kan i nogen tilfælde være personhenførbare.
      Behandling af personhenførbare oplysninger via cookies og lignende teknologier sker på baggrund af
      databeskyttelsesforordningens artikel 6, stk. 1, litra a (samtykke).
    </p>
    <p className="b-privacy-policy__paragraph">
      Du er til enhver tid berettiget til at tilbagekalde dit samtykke. Du kan tilbagekalde dit samtykke ved først at
      klikke på det lille cookieikon, der vises nederst i hjemmesidens venstre hjørne, og derefter klikke på knappen
      ”kun nødvendige” på den informationsboks der popper up. Du kan også afvise cookies ved at ændre dine
      browserindstillinger. Hvor du finder indstillingerne, afhænger af hvilken browser du bruger. Vejledning til,
      hvordan du kan slette cookies i din browser, finder du på nedenstående links. Hvis du bruger flere forskellige
      browsere, skal du huske at slette cookies i dem alle.
    </p>
    <p className="b-privacy-policy__paragraph">
      <a
        className="b-privacy-policy__link"
        href="https://support.google.com/accounts/answer/32050"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Google Chrome
      </a>
      <br />
      <a
        className="b-privacy-policy__link"
        href="https://support.microsoft.com/da-dk/windows/administrer-cookies-i-microsoft-edge-f%C3%A5-vist-tillad-bloker-slet-og-brug-168dab11-0753-043d-7c16-ede5947fc64d"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Microsoft Edge
      </a>
      <br />
      <a
        className="b-privacy-policy__link"
        href="https://support.microsoft.com/da-dk/windows/slet-og-administrer-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Internet Explorer
      </a>

      <br />
      <a
        className="b-privacy-policy__link"
        href="https://support.apple.com/da-dk/guide/safari/sfri11471/mac"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Safari
      </a>

      <br />
      <a
        className="b-privacy-policy__link"
        href="https://support.mozilla.org/da/kb/slet-cookies-fjerne-oplysninger-fra-websteder"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Mozilla Firefox
      </a>

      <br />
      <a
        className="b-privacy-policy__link"
        href="https://help.opera.com/en/latest/security-and-privacy/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Opera
      </a>

      <br />
      <a
        className="b-privacy-policy__link"
        href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Flash
      </a>
    </p>

    <p className="b-privacy-policy__paragraph">
      Vores hjemmeside kan indeholde links til andre hjemmesider. Vi er ikke ansvarlige for indholdet af andre
      hjemmesider eller for deres eventuelle indsamling og behandling af dine personoplysninger, når du besøger siderne.
      Vi anbefaler derfor, at du altid læser den pågældende hjemmesides privatlivs- og cookiepolitik.
    </p>

    <h3 className="b-privacy-policy__sub-inner-title">2.3 Nyhedsbreve</h3>
    <p className="b-privacy-policy__paragraph">
      Hvis du har tilmeldt dig vores nyhedsbrev, behandler vi oplysninger om dit navn og e-mailadresse samt de
      oplysninger, som du i øvrigt giver os i den forbindelse. Formålet med behandlingen af personoplysninger er at
      kunne sende vores nyhedsbrev til dig og i den forbindelse markedsføre vores produkter og virksomhed over for dig.
      Vi behandler også oplysninger om din brug af de nyhedsbreve, vi sender til dig, herunder om du har åbnet et
      nyhedsbrev fra os, om nyhedsbrevet er læst og hvilke links du har klikket på. Disse informationer bruger vi til at
      tilrettelægge og optimere fremtidige nyhedsbreve.
    </p>
    <p className="b-privacy-policy__paragraph">
      Vi bruger artikel 6(1)(a) GDPR (samtykke) som behandlingsgrundlag. Du er til enhver tid berettiget til at trække
      dit samtykke tilbage. Du kan trække dit samtykke tilbage ved at klikke på afmeldingslinket i bunden af dit seneste
      nyhedsbrev. At du trækker dit samtykke tilbage, påvirker ikke den behandling af personoplysninger der allerede har
      fundet sted forud for tilbagetrækningen af samtykket.
    </p>
    <p className="b-privacy-policy__paragraph">
      Når du modtager nyhedsbreve fra os, opbevarer vi oplysningerne om dig frem til det tidspunkt, hvor du framelder
      dig vores nyhedsbrev.
    </p>

    <h2 className="b-privacy-policy__sub-title">3. FRIVILLIGHED</h2>
    <p className="b-privacy-policy__paragraph">
      Vi indsamler personoplysningerne direkte hos dig, og du giver os oplysningerne frivilligt. Afhængig af de konkrete
      omstændigheder, kan konsekvensen ved ikke at give os personoplysningerne være, at vi ikke kan varetage de formål,
      vi har beskrevet ovenfor, herunder at du ikke kan bruge GolfMore Appen.
    </p>

    <h2 className="b-privacy-policy__sub-title">4. OVERFØRSEL TIL LANDE UDENFOR EU/EØS</h2>
    <p className="b-privacy-policy__paragraph">
      Databeskyttelseslovgivningen i lande uden for EU/EØS kan være lempeligere, end den er i Danmark og det øvrige
      EU/EØS. I visse lande har EU-Kommissionen dog fastlagt, at databeskyttelsesniveauet er på højde med det
      beskyttelsesniveau, der er i EU/EØS. Hvis vi overfører dine personoplysninger til lande, hvor det ikke er
      tilfældet, vil overførslen til disse lande ske efter reglerne i kapitel 5 GDPR. Hvis du har spørgsmål til vores
      overførsel af oplysninger til tredjelande, kan du kontakte os på{' '}
      <a
        className="b-privacy-policy__link"
        href="mailto:info@golfmore.eu"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        info@golfmore.eu
      </a>
      .
    </p>

    <h2 className="b-privacy-policy__sub-title">5. MODTAGERE</h2>
    <p className="b-privacy-policy__paragraph">
      Dine personoplysninger vil kunne blive behandlet af andre end os. Det kan f.eks. være af vores samarbejdspartnere
      (golfklubber, golfbaner, driving ranges m.v.), som du køber digitale produkter hos, gennem GolfMore Appen, eller
      af vores IT-serviceudbydere i forbindelse med løbende drift og support af vores IT-systemer, , herunder til brug
      for fremsendelse af nyhedsbreve, analyseopgaver, betalingsløsninger m.v.
    </p>

    <p className="b-privacy-policy__paragraph">
      Vi videregiver oplysninger om dig, når det er nødvendigt som led i driften af vores virksomhed. Når du køber eller
      gør brug af de digitale produkter, du har købt hos vores samarbejdspartnere i GolfMore Appen, videregiver vi
      oplysninger om dit navn, e-mailadresse, medlemsnummer og hjemmeklub samt oplysninger om dit brug af point,
      værdikuponer eller gavekort til den af vores samarbejdspartnere, der skal levere ydelserne til dig. Videregivelsen
      sker på grundlag af artikel 6 (1)(b) GDPR, da videregivelsen er nødvendig for at du kan indgå en aftale med den
      pågældende samarbejdspartner om køb eller brug af de digitale produkter hos dem. Til den pågældende
      samarbejdspartner videregiver vi desuden til brug for forretningsudvikling statistikker og analyser af
      forbrugsmønstre vedrørende deres kunders køb og brug af digitale produkter i GolfMore Appen. Behandling til disse
      formål sker på baggrund af databeskyttelsesforordningens artikel 6, stk. 1, litra f, og vores samarbejdspartneres
      legitime interesse i at kunne videreudvikle deres forretning på baggrund af deres salg af digitale produkter
      gennem GolfMore Appen. For yderligere oplysninger om deres behandling af dine personoplysninger henviser vi til
      deres privatlivspolitikker og vilkår.
    </p>

    <h2 className="b-privacy-policy__sub-title">6. DINE RETTIGHEDER</h2>
    <h3 className="b-privacy-policy__sub-inner-title">Ret til indsigt</h3>
    <p className="b-privacy-policy__paragraph">
      Du har ret til at få indsigt i de oplysninger, vi behandler om dig, samt modtage en række yderligere oplysninger
      om vores behandling af dine personoplysninger.
    </p>
    <h3 className="b-privacy-policy__sub-inner-title">Ret til berigtigelse</h3>
    <p className="b-privacy-policy__paragraph">
      Du har ret til at få forkerte oplysninger om dig selv rettet og til at få suppleret ufuldstændige oplysninger om
      dig selv. Det kan blandt andet ske ved, at du selv kommer med yderligere oplysninger.
    </p>
    <h3 className="b-privacy-policy__sub-inner-title">Ret til sletning</h3>
    <p className="b-privacy-policy__paragraph">
      Du kan anmode om at få dine personoplysninger slettet under visse omstændigheder. I visse tilfælde har du ret til
      at få slettet oplysninger om dig selv. Det har du blandt andet, når oplysningerne ikke længere er nødvendige for
      at opfylde de formål, de er indsamlet eller behandlet til, eller hvis behandlingen af oplysningerne ikke er
      lovlig.
    </p>
    <h3 className="b-privacy-policy__sub-inner-title">Ret til begrænsning af behandling</h3>
    <p className="b-privacy-policy__paragraph">
      I visse tilfælde har du ret til at få begrænset behandlingen af dine personoplysninger. Hvis du har ret til at få
      begrænset behandlingen, betyder det, at vi fremover kun må behandle oplysningerne – bortset fra opbevaring – med
      dit samtykke, eller med henblik på at retskrav kan fastlægges, gøres gældende eller forsvares, eller for at
      beskytte en person eller vigtige samfundsinteresser.
    </p>
    <h3 className="b-privacy-policy__sub-inner-title">Ret til dataportabilitet</h3>
    <p className="b-privacy-policy__paragraph">
      I visse tilfælde har du ret til at modtage de personoplysninger, som du har givet til os, i et struktureret,
      almindeligt anvendt og maskinlæsbart format samt til at få overført disse personoplysninger fra os til en anden
      dataansvarlig uden hindring.
    </p>
    <h3 className="b-privacy-policy__sub-inner-title">Ret til indsigelse</h3>
    <p className="b-privacy-policy__paragraph">
      Du har til enhver tid ret til – af grunde der vedrører din særlige situation – at gøre indsigelse mod vores ellers
      lovlige behandling af dine personoplysninger.
    </p>
    <h3 className="b-privacy-policy__sub-inner-title">Ret til at trække et samtykke tilbage</h3>
    <p className="b-privacy-policy__paragraph">
      Du har til enhver tid ret til at trække et samtykke til behandling af dine personoplysninger tilbage. Du skal dog
      være opmærksom på, at dette først har virkning fra tidspunktet for tilbagetrækningen. Det påvirker derfor ikke
      lovligheden af vores behandling af dine personoplysninger frem til det tidspunkt, hvor du trækker samtykket
      tilbage.
    </p>

    <p className="b-privacy-policy__paragraph">Du kan læse mere om dine rettigheder på Datatilsynets hjemmeside.</p>

    <p className="b-privacy-policy__paragraph">
      Hvis du ønsker at gøre brug af én eller flere af dine rettigheder, kan du kontakte Peter Selmer på telefon +45
      7070 7999 eller e-mail{' '}
      <a
        className="b-privacy-policy__link"
        href="mailto:info@golfmore.eu"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        info@golfmore.eu
      </a>
      .
    </p>

    <h2 className="b-privacy-policy__sub-title">7. KLAGE</h2>
    <p className="b-privacy-policy__paragraph">Klage over vores behandling af dine personoplysninger kan ske til:</p>
    <p className="b-privacy-policy__paragraph">
      Datatilsynet
      <br />
      Carl Jacobsens Vej 35
      <br />
      2500 Valby
      <br />
      E-mail:{' '}
      <a
        className="b-privacy-policy__link"
        href="mailto:dt@datatilsynet.dk"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        dt@datatilsynet.dk
      </a>
    </p>

    <h2 className="b-privacy-policy__sub-title">8. OPDATERING AF PRIVATLIVSPOLITIKKEN</h2>
    <p className="b-privacy-policy__paragraph">
      Det vil fra tid til anden være nødvendigt for os at opdatere denne privatlivspolitik. Det er derfor en god ide at
      holde dig regelmæssigt opdateret. Ved væsentlige ændringer informerer vi dig på GolfMore Appen eller på e-mail. Vi
      offentliggør nye versioner på GolfMore Appen og på vores hjemmeside{' '}
      <a
        className="b-privacy-policy__link"
        href="https://www.golfmore.eu/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        www.golfmore.eu
      </a>
      .
    </p>
  </div>
);

export default privacyPolicyDanish;
